.dropdowncontainer{
    margin-top: 1rem;
    /* height:18vh; */
}

.marginBottomFormControl{
    margin-bottom: 1.5rem;
}

.maincontent{
    font-size: 1.2rem!important;
    font-weight: 500!important;
    
    margin-top: 1rem !important;
}
.maincontent span{
    font-weight: 700;
}