.eventcal{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .eventcal p {
    border: none;
    cursor: pointer;
    color: #07054026;
    text-decoration: none;
    font-size: 4rem;
    font-weight: 600;
  }
  
  .eventcal .active {
    color: #070540;
    text-decoration: none;
  }

  .tabheader {
    display: flex;
    justify-content:space-between;
    margin: 1.6rem 2.4rem;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
  .tabheader {
    padding-top: 9rem;
  }
}