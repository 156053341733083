.container{
    display: flex;
    width: 100%;
}
.tblcontainer{
    min-width: 50%;
    padding: 0;
    margin-right: 2.4rem;
    margin-top: 12.5rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}
.btncontainer{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    top: -12%;
}
.btncontainer .btn{
    background-color: #DB545D;
    border-radius: 0.8rem;
    padding: 1.2rem;
    color: white;
    font-size: 1.4rem;
    margin-right: 1rem;
    font-weight: 600;
}
.btncontainer .btn:hover{
    background-color: #DB545D;
    color:white ;
}
.btncontainer .btnended{
    background-color: white;
    border-radius: 0.8rem;
    padding: 1.2rem;
    color: #DB545D;
    font-size: 1.4rem;
    font-weight: 600;
    margin-left: 2rem;
}
.btncontainer .btnremark{
    border: 1px solid #F87B83;
    border-radius: 0.8rem;
    padding: 1.2rem;
    color: #DB545D;
    font-size: 1.4rem;
    
    font-weight: 600;
}
.btnended:hover,.btnremark:hover{
    background-color: white!important;
    color: #DB545D;
}
.addbtn{
    width: 150px !important;
    margin-top: 1rem !important;
    background-color: #DB545D !important;
    border-radius: 0.8rem !important;
    padding: 1.2rem !important;
    color: white !important;
    font-size: 1.4rem !important;
    margin-right: 1rem !important;
    font-weight: 600 !important;
}
.addbtn:hover{
    background-color: #DB545D !important;
    color:white !important;
}
.tablecontainer{
    padding: 0 1.6rem;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
    .container{
        flex-direction: column;
        padding-top: 9rem;
    }

    .tblcontainer{
        margin-top: 4rem;
    }

    .btncontainer{
        position: fixed;
        right: 2.4rem;
        z-index: 4;
        top: 2rem;
    }
}