.linkcontainer{
    margin-left: 2.4rem;
    margin-right: 2.4rem;
    margin-bottom: 1.6rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.7rem 0;
  }

  .links{
    font-weight: 600;
    font-size: 1.8rem;
    padding: 0.7rem 2rem;
    text-decoration: none;
    color: #000000BF;
    cursor: pointer;
  }

 .active{
    color:#DB545D;
    border-bottom: 3px solid #DB545D;
  }
 

  @media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
    .linkcontainer{
        /* padding-top: 9rem; */
    }
}
