.maincontainer {
    display: flex;
    justify-content: flex-start;
}

.container {
    width: 50%;
    padding: 2rem;
}

.formcontainer {
    width: 48%;
}

.muicontrol:hover {
    border: 1px solid #0000001A;
}

Select.Mui-selected {
    border: #DB545D;
}

.labell,
.eventformat .labell,
.typestatus .labell,
.courtcontainer .labell,
.container .labell {
    font-size: 1.4rem;
    font-weight: 500;
    color: #00000080;
}

.control {
    border-radius: 1.2rem;
    height: 4rem;
    width: 100%;
    border: 1px solid #0000001A;
    padding-left: 1rem;
    background-color: #FFFFFF;
}

.datetime {
    color: #000;
}

textarea {
    padding-top: 0.8rem;
}

.marginTop {
    margin-top: 2rem;
}

input:focus,
textarea:focus {
    border: 1px solid #DB545D;
    /* border-radius: 50%; */
    outline: 0;
}

input:not(:placeholder-shown) {
    background-color: transparent;
    /* Change this to your desired background color */
}

input[type="text"]:not(:placeholder-shown) {
    background-color: #FFFFFF !important;
}

.changedLabel {
    display: block;
}

.title {
    color: #070540;
    font-weight: 600;
    font-size: 4rem;
    padding-bottom: 1.6rem;
}

/* .eventname {
    margin-left: -0.5rem;

} */

.eventformat {
    width: 100%;
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
}

.formatelmnts {
    border: 1px solid #0000001A;
    border-radius: 1.2rem;

}

.choosetext {
    font-size: 1.4rem;
    font-weight: 500;

    color: #DB545D;
}

.selected {
    background-color: #DB545D0D;
    border: 1px solid #DB545D;
    color: #DB545D;
}

.typestatus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 2rem;
}

.datetime {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 2rem;
}

.dateCourt {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom:0;
    padding-top: 1.2rem;
}

.dateCourt1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 2rem;
}

.datecontainer, .courtContainer {
    width: 48%;
}

.time {
    padding-left: 1rem;
    width: 49.5%;
}

.timeWidth {
    width: 49.5%;
}

.timetext {
    /* margin-top: -1rem; */
}

.timelmnt {
    /* margin-top: 2rem; */
    padding: 1rem;
    width: 48%;
    display: inline;
}

.timelmntcontainer {
    display: flex;
    justify-content: space-between;
    height: 4rem;
    gap : 1rem;
    padding: 0;
    margin: 0;
}

.participantscourtcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 2rem;
}

.participantcontainer {
    width: 48%;
}

.courtcontainer {
    margin-top: 1.5rem;
    width: 100%;
}

.court {
    height: 4vh;
}

.workerscontainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.totalworkercontainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    formgroup{
        width: 50%;
        &:first-child{
            margin-right: 1rem;
        }
    }
}
.workers {
    width: 48%;
    display: flex;
    position: relative;
    margin-bottom: 2rem;
}

.workerstext {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #f4f4f4;
    width: 7rem;
    text-align: center;
    right: 0;
    height: 100%;
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    border: 1px solid #d9d9d9;
    font-size: 1.2rem;
    color: #7e7e7e;
}

.goldimg {
    margin-right: 0.4rem;
}

.amountcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
}

.amount {
    width: 49%;
    margin-bottom: 1rem;
}
.totalWorkers{
    /* margin : 0 0 1rem 0; */
    margin-bottom: 1rem;
}

.leadsmaincontainer {
    /* background-color: blue; */
    display: flex;
    align-items: center;
    margin-left: 0rem;
    margin-bottom: 1rem;
    gap: 1rem;
}
.leadsmaincontainer formgroup{ 
    width: 50% !important;
    /* background-color: red !important; */
}
.foodcost{
    margin-left: .5rem;
}

.leadcontainer {
    width: 99%;
    /* background-color: blue; */
}

.lead {
    height: 4vh;
}

.techmanagercontainer,
.howleadcontainer {
    margin-top: 1rem;
}

.ballimg {
    width: 50%;
    position: "sticky";
    top:"4rem";
    /* padding-top: 15%; */
    /* padding-left: auto; */
    padding-right: auto;
    display: inline-block;
    position: relative;
    /* margin:0 auto; */
    margin-top: 6rem;
    padding-right: 2.4rem;
}

.bimg {
    background-image: url("../../images/createeventImg.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: fixed;
    bottom: 0;
    left:auto;
    right:0;
    margin:0 auto;
    width: 40%;
    height: 50%;
    /* z-index: -1; */
}

input[type="checkbox"].checkbox:checked {
    accent-color: #DB545D !important;
}

.checkboxtext {
    font-size: 1.4rem;
    font-family: "Poppins";
    font-weight: 500;
    color: #00000080;
    padding-left: 1rem;

}

.checkboxcontainer {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
}

.btncontainer {
    padding: 2rem 0;
    width: 46%;
    display: flex;
    /* justify-content: space-between; */
}

.btnsubmit,
.btncancel {
    border: none;
    padding: 1.5rem 3rem;
    border-radius: 0.8rem;
    font-size: 1.4rem;
    font-weight: 600;
    margin-right: 1.5rem;
}

.btnsubmit {
    color: white;
    background: linear-gradient(148.15deg, #F87B83 1.3%, #FFFFFF 201.37%);
}

.btncancel {
    background: linear-gradient(148.15deg, #F87B83 1.3%, #FFFFFF 201.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid #F87B83;
}

.btnAddGroup {
    color: #DB545D;
    background-color: transparent;
    font-weight: 500;
    margin: 10px 0;
    font-size: medium;
    border: 0;
}

.selecttext,
input::placeholder,
textarea::placeholder {
    font-size: 1.4rem;

    font-weight: 500;
    font-style: normal;
    color: #00000040;

}

input[type="date"],
input[type="time"] {
    position: relative;
    color: #000;
    padding-left: 1.5rem;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0.3;
    padding-right: 2rem;
}

.radiogroup .choosetext {
    font-size: 1.4rem;
    font-weight: 500;
    color: #00000040;
}

input,
textarea {
    font-size: 1.4rem;
    font-weight: 500;
    font-style: normal;
}

.radioselectcontainer {
    display: flex;

}

.emailcontainer {
    margin-bottom: 2rem;
}

.howleadcontainer {
    padding-bottom: 2rem;
}

.surveycontainer {
    padding-bottom: 2rem;
}

.remarkcontainer {
    padding-bottom: 2rem;
}

.paymentstatuscontainer {
    padding-top: 0 !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
    .ballimg{
        display: none;
    }

    .container {
        width: 75%;
        padding-top: 9rem;
    }
}

