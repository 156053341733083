.tablecontainer {
  margin: 2rem 2.4rem;
  position: relative;
  max-height: 60vh; /* Set a max-height for the table container */
  overflow-y: auto; /* Enable vertical scrolling */
  th{
    position: sticky !important;
    top : 0 !important;
  }
}


.eventoptions {
  position: relative;
}