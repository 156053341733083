.btn{
    width: auto;
    border: 1px solid #c4c4c4;
    display: flex;
    padding: 1.4rem 3rem;
    align-items: center;
    border-radius: 1.2rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #c4c4c4;
    background: white;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
}
.hours{
    padding-left: 3.2rem;
}
.btn .img{
    padding-right: 1rem;
    opacity: 0.35;
}
.active{
    border: 1px solid #DB545D;
    background: #DB545D0D;
    color: #DB545D;
}
.active .img{
    filter: brightness(0) saturate(100%) invert(41%) sepia(53%) saturate(658%) hue-rotate(307deg) brightness(96%) contrast(98%);
    opacity: 1;
}