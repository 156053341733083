/* .calendar_container{
    width: 100vw;
    height: 100%;
    background-color: rgba(0,0,0, 0.2);
    transition: all 0.3s ease;
} */
 
.container{
    position: relative;
    background-color: #ffff;
    padding: 24px;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: scroll;
    scrollbar-width: none;
}
.calendar_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.calendar_title h1{
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
}

.calendar_title .info{
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #00000080;
}
.table_container table{
    width: 100%;
    /* border-collapse: collapse; */
    border-spacing: 4px; /* Adjust spacing as needed */
    border-collapse: separate; 
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
}
.table_container{
    padding: 8px;
    background-color: #00000008;
    margin-top: 24px;
    border-radius: 12px;
    overflow-x: hidden;
    width: 100%;
    
   
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
.text_red{
    color: #DB545D;
}
th{
    padding: 12px;
}

.timeslot_cell{
    padding: 12px;
    border-bottom: 1px solid #0000000D;
}
tbody tr:last-child .timeslot_cell{
    border-bottom: 1px solid transparent;
}

.table_cell{
    padding: 12px;
    border-radius: 12px;
    vertical-align: top;
}
.available{
    background-color: #25B9180D;
}
.available .status_indicator{
    width: 3px;
    height: 16px;
    border-radius: 100px;
    background-color: #25B91880;
}
.not_available .status_indicator{
    width: 3px;
    height: 16px;
    border-radius: 100px;
    background-color: #DB545D;
}
.not_available{
    background-color: #DB545D0D;
}
.occupancy_status {
    display: flex;
    align-items: center;
    gap: 12px;
}
@media (max-width: 1057px){
    .table_container {
        /* width: 1232px; */
        overflow-x: scroll;
    }
    .table_container table{
        width: 100%;
    }
}
