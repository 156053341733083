.control{
    border-radius: 1.2rem;
    height: 4rem;
    width: 100%;
    /* margin-bottom: 1rem; */
    /* padding-bottom: 0; */
    border: 1px solid #0000001A;
}
.labell{
    font-size: 1.4rem !important;
    
    font-weight: 600 !important;
    color: #00000080;
    margin-top: 1rem;
}
.lastcontrol{
    margin-bottom: 2rem;
}