.boxcontainer {
  position: relative;
}

.box {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  background: white;
  border: 1px solid #0000001A;
  border-radius: 0.8rem;
  z-index: 1;
}

.btngrp {
  width: 22rem;
}

.btn {
  color: #000000BF;
  text-decoration: none;
  border-bottom: 1px solid #0000001A;
  display: flex;
  align-items: center;
  height: 5rem;
  cursor: pointer;
}

.one,
.two,
.three,
.four {
  margin: 0;
  padding: 0;
}

.three {
  color: #DB545D;

}

.icon {
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1rem;
}