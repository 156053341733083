.logo{
    padding-left:2.4rem;
   
}
.neojalogo{
    cursor: pointer;
}
.logotext{
    display: inline;
    vertical-align: middle;
    font-weight: 600;
    font-size: 2rem;
    color:#000000;
    padding-left: 1.5rem;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
    .eventcal .style {
      font-size: 3rem;
      margin-right: 1.5rem;
    }
  
    .logo{
      position: fixed;
      background: #fff;
      z-index: 0;
      width: 100%;
      left: 0;
      z-index: 1;
    }
  }