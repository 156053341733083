.eventcal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eventcal p {
  border: none;
  cursor: pointer;
  color: #07054026;
  text-decoration: none;
  font-size: 4rem;
  font-weight: 600;
  margin-right: 2.5rem;
}

.eventcal .active {
  color: #070540;
  text-decoration: none;
}

.tabheader {
  display: flex;
  justify-content: space-between;
  margin: 1.6rem 2.4rem;
}

.linkcontainer {
  padding: 0.7rem 0;
  margin-left: 2.4rem;
  margin-right: 2.4rem;
  margin-bottom: 1.6rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.links {
  font-weight: 600;
  font-size: 1.8rem;
  padding: 0.7rem 2rem;
  text-decoration: none;
  color: #000000BF;
  cursor: pointer;
}

.active {
  color: #DB545D;
  border-bottom: 3px solid #DB545D;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
  .tabheader {
    padding-top: 9rem;
  }
}