.text{
    color: #DB545D;
    font-size: 1.4rem;
    font-weight: 500;
    font-family: "Poppins";
    cursor: pointer;
}

.registerimg{
    position: relative;
    padding-right: 0.5rem;
    top: -1px;
}