@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  font-family: "Poppins",sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-gutter: stable ;
}

html{
  font-size: 10px;
}
#root{
  position: relative;
}

@media only screen and (max-width: 600px) {
  html{
    font-size: 8px;
  }
}

@media (min-width: 1440px) and (max-width: 1679px) {
  
}

@media (min-width: 1680px) {
  html{
    font-size: 11px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
  html{
    font-size: 9px;
  }
}


input[type="text"], input[type="number"], input[type="password"]{
  padding-left: 1rem;
} 

input[type="checkbox"]{
  cursor: pointer;
  height: 1.6rem;
  width: 1.6rem;
}

input[type="checkbox"] + label{
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    width: 1.4rem;
    height: 1.4rem;
    position: absolute;
    right: -1rem;
}

input[type="number"]:disabled{
  background-color: #f3f3f3 !important;
}

textarea{
  height: 8rem !important;
  resize: none;
}

a:focus, button:focus{
  outline: none !important;
}

.cursor-pointer{
  cursor: pointer;
}

.eventcal{
  width:45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eventcal p {
  border: none;
  cursor: pointer;
  color: #07054026;
  text-decoration: none;
  font-size: 4rem;
  font-weight: 600;
}

.eventcal .active {
  color: #070540;
  text-decoration: none;
}

em{
  color: #c9c9c9 !important;
  font-style: normal !important;
}

td{
  font-size: 1.4rem !important;
}

th{
  font-weight: 600 !important;
  font-size: 1.4rem !important;
}

.stickyTable{
  position: sticky;
  top: 0;
}

/* Calendar css update */
.rbc-day-bg + .rbc-day-bg{
  border-color: #e7e7e7 !important;
}

.rbc-header{
  padding: 1rem 2rem !important;
  border-bottom-color: #e7e7e7 !important;
}

.rbc-header + .rbc-header{
  border-left-color: #e7e7e7 !important;
}

.rbc-month-view{
  border: 1px solid #e7e7e7 !important;
}

.rbc-month-row + .rbc-month-row{
  border-top: 1px solid #e7e7e7 !important;
}

.rbc-off-range-bg{
  background-color: #f6f6f6 !important;
}

.rbc-toolbar button:hover{
  border-color: transparent !important;
}

.rbc-event{
  width: calc(100% - 2rem)  !important;
  margin: 0 1rem 0.5rem 1rem  !important;
  padding: 0.3rem 0.6rem !important;
}

.rbc-date-cell{
  padding-top: 1rem !important;
}

.rbc-toolbar button:focus{
  border-color: none !important;
}

/* No data css */
.noData{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem 0;
}

.noData img{
  width: 10rem;
  margin-bottom: 2rem;
}

.noData b{
  font-weight: 600;
  font-size: 1.8rem;
}

.noData p{
  color: rgba(0,0,0,0.5);
  font-size: 1.4rem;
}

/* Mobile responsive css */

@media only screen and (max-width: 600px) {
  .rbc-month-row{
    max-height: 10rem !important;
  }

  .tableTabletEmployeeEvents, .tableTabletManager{
    min-width: 105rem;
  }
}

/* Tablet responsive css */
@media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
  .tableTabletAdminEvents{
    min-width: 120rem;
    /* min-height: 60vh; */
  }

  .tableTabletManager{
    min-width: 95rem;
  }
  
  .tableTabletEmployeeEvents{
    min-width: 95rem;
  }

  .viewEmployeeTabletTable{
      /* min-height: 50vh; */
  }

  tr{
    vertical-align: top !important;
  }
}

/* extra css */
.css-12v7fep-MuiPaper-root-MuiAccordion-root::before{
  content: none !important;
}