.labell{
    font-size: 1.4rem;
    font-weight: 500;
    color: #00000080;
}
.selecttext{
    font-size: 1.4rem;
    font-weight: 500;
    font-style: normal;
    color: #00000040;
}