.eventcal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eventcal .style {
  border: none;
  cursor: pointer;
  color: #07054026;
  text-decoration: none;
  font-size: 4rem;
  font-weight: 600;
  margin-right: 2.5rem;
}

.eventcal .active {
  color: #070540 !important;
  text-decoration: none;
}

.tabheader {
  display: flex;
  justify-content: space-between;
  margin: 1.6rem 2.4rem;
}


@media only screen and (max-width: 600px) {
  .eventcal .style {
    font-size: 3rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
  .eventcal .style {
    font-size: 3rem;
    margin-right: 1.5rem;
  }

  .tabheader {
    padding-top: 9rem;
  }
}