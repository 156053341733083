.container {
  margin: 2rem 2.4rem;
  position: relative;
}
.btn,.btnabsent{
  border-radius: 0.8rem;
  background: linear-gradient(148.15deg, #F87B83 1.3%, #FFFFFF 201.37%);
  color:white!important;
  font-size: 1.4rem;
  font-weight: 600;
  
  padding: 1.2rem;
  position: absolute;
  border: none;
  
}
.btn{
  top: -7.5rem;
  right: 0;
}
.btnabsent{
  top: -41rem;
  right: 0;
}

.employeeContainer {
  margin: 2rem 2.4rem;
  position: relative;
  max-height: 60vh; /* Set a max-height for the table container */
  overflow-y: auto; /* Enable vertical scrolling */
}

.stickyHeader th {
  position: sticky;
  top: 0;
  /*background-color: white; /* Ensure the header background remains visible */
  /*z-index: 1; /* Ensure the header stays above other content */
}