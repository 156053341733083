.container {
    padding: 5rem 2.4rem;
}

.title {
    color: #070540;
    font-size: 5rem;
    font-weight: 600;
}

.subtitle {
    color: #000000BF;
    font-size: 1.6rem;
}

.subtitle span {
    background: linear-gradient(148.15deg, #F87B83 1.3%, #FFFFFF 201.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.circle {
    height: 3.4rem;
    color: #DB545D;
    background: #DB545D1A;
    border-radius: 10rem;
    background-color: #DB545D1A;
    font-size: 1.4rem;
    padding: 1.2rem;
    text-align: center;
    margin-right: 1rem;
    margin-top: 0.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: auto;
}

.circlecontainer {
    display: flex;
}

.imgcontainer {
    display: flex;
    width: 100%;
    height: 48rem;
    padding: 0 2.4rem;
    margin-bottom: 2rem;
}

.employee,
.event,
.admin {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 38rem;
    margin-right: 3rem;
    border-radius: 0.5rem;
    position: relative;
}

.admin {
    margin-right: 0 !important;
}

.employee {
    background-image: url('../../images/empImg.png');
}

.event {
    background-image: url('../../images/eventImg.png');
}

.admin {
    background-image: url('../../images/adminImg.png');
}

.text {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 3.4rem;
    padding-top: 2.5rem;
    padding-left: 2rem;
}

.btn {
    width: 25%;
    height: 20%;
    border-radius: 50%;
    border: none;
    top: 81%;
    position: absolute;
}

.btn img {
    width: 50%;
    height: 50%;
}

.btnemp {
    background: linear-gradient(143.96deg, #4BBEEB -5.06%, #6590D5 100%);
}

.btnevent {
    background: linear-gradient(148.15deg, #F87B83 1.3%, #FFFFFF 201.37%);
}

.btnadmin {
    background: linear-gradient(146.85deg, #F3C967 0%, #F1A37F 103.83%);
    margin-left: 2%;
}

@media only screen and (max-width: 600px) {

    .imgcontainer {
        flex-direction: column;
        height: auto;
    }

    .employee,
    .event,
    .admin {
        width: 100%;
        height: 20rem;
        background-size: cover;
        border-radius: 3rem;
        margin-bottom: 2rem;
    }

    .btn {
        height: 6rem;
        width: 6rem;
        bottom: 1rem;
        position: absolute;
        right: 1rem;
        box-shadow: 0 0 5px #898989;
        top: unset !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
    .employee,
    .event,
    .admin {
        width: 100%;
        background-size: cover;
        border-radius: 2rem;
        margin-bottom: 2rem;
    }
    
    .btn {
        width: 35%;
    }
    
    .container{
        padding-top: 9rem;
    }
}