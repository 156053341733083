.dialog {
    width: 31%;
    display: block !important;
    margin: 0 auto !important;
}

@media only screen and (max-width: 600px) {
    .dialog {
        width: 90% !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
    .dialog {
        width: 50% !important;
    }
}

.dialogtitle {
    color: '#DB545D' !important;
    border-bottom: 1px solid #0000001A !important;
    font-size: 2.4rem !important;
    font-weight: 600 !important;

    margin: 0 2rem !important;
    padding: 1.6rem 0 !important;
    position: relative !important;
}

.content {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.maincontent {
    font-size: 1.6rem !important;
    font-weight: 500 !important;

    color: black !important;
}

.subcontent {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
}

.btncontainer {
    justify-content: flex-start !important;
    padding-left: 24px !important;
    padding-bottom: 2rem !important;
}

.btnno {
    border: 1px solid #DB545D !important;
    border-radius: 0.8rem !important;
    padding: 1.2rem !important;
    font-size: 1.4rem !important;
    font-weight: 600 !important;
    margin-left: 1.5rem !important;
}

.btnyes {
    color: white !important;
    background-color: #DB545D !important;
    border: 1px solid #DB545D !important;
    border-radius: 0.8rem !important;
    padding: 1.2rem !important;
    font-size: 1.4rem !important;
    font-weight: 600 !important;
}

.closebtn {
    position: absolute;
    right: 0;
    cursor: pointer;
    display: inline;
}