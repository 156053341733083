.container {
  padding: 2.4rem;
  min-width: 48%;
}

.widthfifty{
  min-width: calc(50% - 2.4rem);
}

.titlecontainer {
  display: flex;
  padding-left: 2.4rem;
  margin-top: 2rem;
}

.title {
  color: #070540;
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.back {
  width: 5rem;
  height: 8rem;
  background-color: #0000000D;
  cursor: pointer;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back img {
  width: 1rem;
}

.eventdetailscontainer {
  padding-left: 2rem;
}

.detailscontainer {
  display: flex;
}

.detailscontainer p {
  font-size: 1.6rem;
  font-weight: 600;
  color: #00000080;
  margin: 0;
  padding-right: 2.5rem;
}

.iconstop {
  padding-right: 0.5rem;
  width: 2rem;
  position: relative;
  top: -1px;
}

.moredetailscontainer {
  border: 1px solid #0000000D;
  border-radius: 1.2rem;
  background-color: #00000005;

}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #0000000D;
  padding: 1.6rem;
}

.header p {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 600;
}

.signed p {
  color: #6196FF;
}

.provided {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.provided p {
  color: #DB545D;
  font-size: 1.4rem;
  border-radius: 1.2rem;
  border: 1px solid #FFFFFF;
  background-color: rgba(219, 84, 93, 0.1);
  padding: 0.8rem;
  margin-left: 1rem;
}

.extradetailscontainer {
  padding: 2.4rem;
}

.assignedvalue {
  font-size: 2rem;
  font-weight: 600;
  color: black;
}

.assignedetails {
  color: #00000080;
  font-size: 1.4rem;
  font-weight: 600;
}

.icons {
  padding-right: 1rem;
}

.formattype {
  padding-top: 1rem;
}

.formattype,
.participantpayment,
.amountavg,
.leadhours {
  display: flex;
  padding-bottom: 1rem;
}

.formattype div,
.participantpayment  div,
.amountavg  div,
.leadhours  div{
  width: 50%;
}

.titlelike {
  color: #00000080;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
}

.valuelike {
  color: #000000;
  font-size: 1.6rem;
  font-weight: 600;
}


.foodcontainer ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin-top: .5rem;
  margin-bottom: 2.5rem;
  width: 100%;
}

.foodcontainer ul li {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  border-radius: 0.8rem;
  color: #00000080;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.1rem;
  margin: 0 1rem 0 0;
}

.courtscontainer {
  background-color: rgba(0, 0, 0, 0.03);
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #0000000D;
}

.courts {
  padding: 1rem 0rem;
  flex: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
}

.courtscontainer div:last-child{
  border-right: none;
}

.middlecourt {
  border-right: 1px solid #0000000D;
  border-left: 1px solid #0000000D;
  padding: 1rem 6rem;
}

.courts p {
  text-align: center;
  margin: 0;
}

.courtnum {
  color: black;
  font-size: 1.8rem;
  font-weight: 600;
}

.courtname {
  color: #00000080;
  font-size: 1.4rem;
  font-weight: 500;
}

.courtimgs {
  margin-right: 0.5rem;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
  .titlecontainer {
    
  }

  .widthfifty{
    min-width: 100% !important;
  }

  .container {
    flex-direction: column;
    min-width: 100%;
  }
}