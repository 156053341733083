.buttons {
  display: flex;
  justify-content: space-between;
}

.download,
.create {
  border: none;
  border-radius: 0.8rem;
  font-size: 1.4rem;
  font-weight: 600;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.download p {
  margin: 0;
  padding: 0;
  background: linear-gradient(148.15deg, #F87B83 1.3%, #FFFFFF 201.37%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.download {
  border: 1px solid #F87B83;
  background-color: white;
}

.download img {
  margin-right: 1rem;
}

.create {
  background: linear-gradient(148.15deg, #F87B83 1.3%, #FFFFFF 201.37%);
  color: white;
}