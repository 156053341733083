.container {
    display: flex;
}

.tblcontainer {
    min-width: 50%;
    padding-right: 2.4rem;
    margin-top: 12.5rem;
    margin-left: 2rem;
    position: relative;
}

.paymentstatus {
    position: absolute;
    font-size: 2rem;
    font-weight: 600;
    color: #DC7F29;
    right: 2.4rem;
    top: -8rem;
}

.newempbtn {
    background: linear-gradient(148.15deg, #F87B83 1.3%, #FFFFFF 201.37%);
    border-radius: 0.8rem;
    padding: 1.2rem;
    color: white;
    font-size: 1.4rem;
    font-weight: 600;
    border: none;
    margin-top: 2.5rem;
    margin-left: 1rem;
}



@media only screen and (min-device-width: 768px) and (max-device-width: 1179px){
    .container{
        flex-direction: column;
        padding-top: 9rem;
    }

    .tblcontainer{
        margin-top: 4rem;
    }

    .paymentstatus{
        top: -4rem;
    }
}
@media only screen and (max-device-width: 767px){
    .container{
        flex-direction: column;
        /* padding-top: 9rem; */
    }

    .tblcontainer{
        margin-top: 4rem;
    }

    .paymentstatus{
        top: -4rem;
    }
}
