.marginY {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.flexTop {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.paddingBottom {
    padding-bottom: 1rem;
}

.rotateRight {
    transform: rotate(70deg);
}
button:disabled{
    cursor: not-allowed !important;
}
a,button{
    cursor: pointer !important;
}