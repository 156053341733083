.maincontent{
    font-size: 1.6rem!important;
    font-weight: 500!important;
    
    color: black !important;
    margin-top: 1.5rem;
}
.subcontent{
    font-size: 1.2rem!important;
    font-weight: 500!important;
    
}