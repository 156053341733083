.adminMainContainer {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;

  @media only screen and (max-width: 768px) {
    grid-template-columns: auto;
  }
}

.container {
  padding: 2.4rem;
  min-width: 48%;

  @media only screen and (max-width: 768px) {
    min-width: 100%;
  }
}

.widthfifty {
  min-width: calc(50% - 2.4rem);
}

.btncontainer .btn {
  background-color: #db545d;
  border-radius: 0.8rem;
  padding: 1.2rem;
  color: white;
  font-size: 1.4rem;
  margin-right: 1rem;
  font-weight: 600;
}

.btncontainer .btn:hover {
  background-color: #db545d;
  color: white;
}

.titlecontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2.4rem;
  width: 100%;
  height: 100%;
  padding-left: 2.4rem;
  margin-top: 2rem;
}

.title {
  color: #070540;
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.back {
  width: 5rem;
  height: 8rem;
  background-color: #0000000d;
  cursor: pointer;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back img {
  width: 1rem;
}

.eventdetailscontainer {
  padding-left: 2rem;
}

.detailscontainer {
  display: flex;
}

.detailscontainer p {
  font-size: 1.6rem;
  font-weight: 600;
  color: #00000080;
  margin: 0;
  padding-right: 2.5rem;
}

.iconstop {
  padding-right: 0.5rem;
  width: 2rem;
  position: relative;
  top: -1px;
}

.moredetailscontainer {
  border: 1px solid #0000000d;
  border-radius: 1.2rem;
  background-color: #00000005;
  margin-bottom: 1.6rem;
}

.newempbtn {
  background: linear-gradient(148.15deg, #F87B83 1.3%, #FFFFFF 201.37%);
  border-radius: 0.8rem;
  padding: 1.2rem;
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  border: none;
  margin-top: 2.5rem;
  margin-left: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #0000000d;
  padding: 1.6rem;
}

.header p {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 600;
}

.signed p {
  color: #db545d;
}

.provided {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.provided p {
  color: #db545d;
  font-size: 1.4rem;
  border-radius: 1.2rem;
  border: 1px solid #ffffff;
  background-color: rgba(219, 84, 93, 0.1);
  padding: 0.8rem;
  margin-left: 1rem;
}

.extradetailscontainer {}

.assignedvalue {
  font-size: 2rem;
  font-weight: 600;
  color: black;
}

.assignedetails {
  color: #00000080;
  font-size: 1.4rem;
  font-weight: 600;
}

.icons {
  padding-right: 1rem;
}

.formattype {
  padding-top: 1rem;
}

.assigned {
  margin: 1.6rem;
}

.participantpayment,
.foodcontainer,
.remarkscontainer {
  margin: 0 1.6rem;
}

.employeetablecontainer {
  padding: 0 1.6rem;
}

.endContainer div:nth-child(2) {
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #0000000d;
}

.endContainer {
  display: grid;
  grid-template-columns: auto;
  /* grid-template-columns: auto auto auto; */
  text-align: center;
  border-top: 1px solid #0000000d;
}

.endContainerDiv {
  padding: 1.6rem;
}

.formattype,
.participantpayment,
.leadhours {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-columns: auto auto auto;
  row-gap: 20px;
  grid-auto-flow: row;
  padding-bottom: 1rem;
}

.formattype div,
.participantpayment div,
.leadhours div {
  min-width: 50%;
}

.titlelike {
  color: #00000080;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
}

.valuelike {
  color: #000000;
  font-size: 1.6rem;
  font-weight: 600;
}

.foodcontainer ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin-top: 0.5rem;
  width: 100%;
}

.foodcontainer ul li {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  border-radius: 0.8rem;
  color: #00000080;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.1rem;
  margin: 0 1rem 0 0;
}

.courtscontainer {
  background-color: rgba(0, 0, 0, 0.03);
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #0000000d;
}

.courts {
  padding: 1rem 0rem;
  flex: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
}

.courtscontainer div:last-child {
  border-right: none;
}

.middlecourt {
  border-right: 1px solid #0000000d;
  border-left: 1px solid #0000000d;
  padding: 1rem 6rem;
}

.courts p {
  text-align: center;
  margin: 0;
}

.courtnum {
  color: black;
  font-size: 1.8rem;
  font-weight: 600;
}

.courtname {
  color: #00000080;
  font-size: 1.4rem;
  font-weight: 500;
}

.courtimgs {
  margin-right: 0.5rem;
}

.paymentstatus {
  /* position: absolute; */
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  color: #dc7f29;
  right: 2.4rem;
  /* top: -8rem; */
}

.show_calendar_btn {
  background: linear-gradient(148.15deg, #F87B83 1.3%, #FFFFFF 201.37%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 1px solid #F87B83;
  /* border: none; */
  padding: 1.2rem;
  border-radius: 0.8rem;
  font-size: 1.4rem;
  font-weight: 600;
  margin-left: 1.6rem;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1179px) {
  .title {
    margin-top: 8rem;
  }

  .back {
    margin-top: 8rem;
  }

  .btncontainer {
    margin-top: 8rem;
  }

  .titlecontainer {
    margin: 0;
  }

  .widthfifty {
    min-width: 100% !important;
  }

  .container {
    flex-direction: column;
    min-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .titlecontainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .btncontainer {
    margin-top: 2rem;
    display: flex;
    width: 100%;
  }

  .btncontainer .btn {
    width: 100%;
  }
}

/* Local Design */
.remarkWrapper {
  width: 100%;
  height: 8rem;
  background-color: #0000000d;
  cursor: pointer;
  border-radius: 1.2rem;
  padding: 1.6rem;
}

.remarkTitle {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #00000080;
}

.remarkDisc {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #000000;
}