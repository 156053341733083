.checkboxtext{
    font-size: 1.4rem;
    font-family: "Poppins";
    font-weight: 500;
    color:#00000080;
    padding-left: 1rem;

}
.checkboxcontainer{
    display: flex;
    align-items: center;
    padding: 1rem 0;
}
input[type="checkbox"].checkbox:checked{
    accent-color: #DB545D !important;
}
