$out-of-range-color: lighten(#333, 40%) !default;
$out-of-range-bg-color: lighten(#333, 70%) !default;

$calendar-border: #00000040!default;
$cell-border: #00000040 !default;

$border-color: #ccc !default;

// Each calendar segment is 1/7th.
$segment-width: 0.14286% !default;

$time-selection-color: #DB545D !default;
$time-selection-bg-color: #DB545D(0, 0, 0, 0.5) !default;
$date-selection-bg-color: #DB545D(0, 0, 0, 0.1) !default;

$event-bg:  #DB545D!default;
$event-border: darken(#DB545D, 10%) !default;
$event-outline: #DB545D !default;
$event-color: #fff !default;
$event-border-radius: 5px !default;
$event-padding: 0.8rem !default;
$event-zindex: 4 !default;

$btn-color: #DB545D !default;
$btn-bg: #fff !default;
$btn-border: #fff !default;

$current-time-color: #DB545D !default;

$rbc-css-prefix: rbc-i !default;

$today-highlight-bg: #DB545D0D!default;


@import 'react-big-calendar/lib/sass/styles.scss';
.rbc-calendar{
    height: 80rem !important;
}
.rbc-toolbar{
    display: block;
}
.rbc-toolbar-label{
    font-size: 1.8rem;
    font-weight: 600;
    color:#000000BF;
    text-align: left;
}

.rbc-btn-group{
    position: absolute;
    right: 2.4rem;
}
.rbc-btn-group button:hover,.rbc-btn-group button:focus{
    background: linear-gradient(148.15deg, #e9e9e9 1.3%, #FFFFFF 201.37%);
}
.rbc-month-view{
    border-radius: 1.2rem;
}
.rbc-header{
    padding: 2rem;
}
.rbc-header{
    font-size: 1.4rem;
    font-weight: 600;
    
    color: #000000BF;
}
.rbc-date-cell{
    text-align: left;
    padding-left: 1rem;
}
.rbc-button-link{
    font-size: 1.4rem;
    
    font-weight: 600;
    color:#000000BF;
}
.rbc-event-content{
    font-size: 1rem;
    
    font-weight: 600;
}